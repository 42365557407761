import React from "react"
import SEO from "../components/seo"
import Header from "../components/headers/contact/Header";
import Navigator from "../components/navigator/DefaultNavbar";
import Footer from "../components/footer/DefaultFooter";
import Contact from "../components/contact/Contact";
import CookieConsent from "../components/cookieConsent/CookieConsent"

const IndexPage = () => (
  <>
    <SEO
      title="Contact us"
      description="Fill out the form and we’ll be in touch as soon as possible." />
    <Navigator
      useSpaNav={true}
    />
    <Header
      big={true}
      title="Contact us"
      subtitle="We will get back to you as soon as possible."
    />
    <Contact />
    <Footer />
    <CookieConsent />
  </>
)

export default IndexPage
