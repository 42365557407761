import axios from 'axios';
import * as React from 'react';
import {
	Button,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
	FormText,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Modal,
	Alert,
	Spinner,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

function ContactForm() {
	const [fullName, setFullName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [acceptTerms, setAcceptTerms] = React.useState(false);
	const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
	const [confirmDialogOpened, setConfirmDialogState] = React.useState(false);
	const [captcha, setCaptcha] = React.useState('');
	const [confirmMessage, setConfirmMessage] = React.useState(null);
	const [isLoading, setIsLoadingState] = React.useState(false);

	const [errors, setErrors] = React.useState({});

	const isFormDisabled = !fullName && !email && !phone && !message;
	const isConfirmDialogDisabled = !!Object.keys(finalValidateData()).length;

	function validateDate() {
		const errors = {};

		if (!fullName) {
			errors.firstName = 'This field is required!';
		} else if (fullName.length < 2 || fullName.length > 50) {
			errors.firstName = 'The name should have between 2 and 50 characters!';
		}

		if (!email) {
			errors.email = 'This field is required!';
		} else if (!/^\w[\w.-]*@([\w-]+\.)+[\w-]+$/gi.test(email)) {
			errors.email = 'The email is not valid!';
		}

		if (phone && phone.length > 20) {
			errors.phone = 'The phone number should have maximum 20 characters!';
		}

		if (!message) {
			errors.message = 'This field is required!';
		} else if (message.length < 10 || message.length > 5000) {
			errors.message = 'The message should have between 10 and 5000 characters!';
		}

		return errors;
	}

	function finalValidateData() {
		const errors = {};

		if (!acceptTerms) {
			errors.acceptTerms = 'This field is required!';
		}

		if (!privacyPolicy) {
			errors.privacyPolicy = 'This field is required!';
		}

		if (!captcha) {
			errors.captcha = 'This field is required!';
		}

		return errors;
	}

	function onSubmit(e) {
	  e.preventDefault();
		const errors = validateDate();

		if (Object.keys(errors).length) {
			setErrors(errors);
		} else {
			setErrors({});
			setConfirmDialogState(!confirmDialogOpened);
		}
	}

	function onFinalSubmit() {
		const finalErrors = finalValidateData();

		if (!Object.keys(finalErrors).length) {
			setConfirmDialogState(false);
			setIsLoadingState(true);

			axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/contact`, {
					fullName,
					email,
					phoneNumber: phone,
					message,
					captcha,
				})
				.then(() => {
					setFullName('');
					setEmail('');
					setPhone('');
					setMessage('');
					setErrors('');
					setAcceptTerms(false);
					setPrivacyPolicy(false);
					setConfirmMessage('success');
				})
				.catch(() => {
					setConfirmMessage('error');
				})
				.finally(() => {
					window.scrollTo(0, 0);
					setIsLoadingState(false);
				});
		}
	}

	function toggleModal() {
		setConfirmDialogState(!confirmDialogOpened);
	}

	return (
		<form>
			<Row>
				<Col xs={12}>
					<Alert color="success" isOpen={confirmMessage === 'success'} toggle={() => setConfirmMessage(null)}>
						Thank you, your message has been sent successfully! <br/>
						We will get back to you as soon as possible.
					</Alert>
				</Col>
				<Col xs={12}>
					<Alert color="danger" isOpen={confirmMessage === 'error'} toggle={() => setConfirmMessage(null)}>
						Unfortunately your message wasn't sent! <br/>
						Please try again later.
					</Alert>
				</Col>
			</Row>
      <FormGroup className={`${!!errors['fullName'] ? 'error' : ''}`}>
        <Label for="fullName">Full name</Label>
        <div className="field-wrapper">
          <Input name="fullName" id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} invalid={!!errors['fullName']} />
          {errors['fullName'] ? (
            <FormText color="danger">{errors['fullName']}</FormText>
          ) : null}
        </div>
      </FormGroup>
			<FormGroup className={`${!!errors['email'] ? 'error' : ''}`}>
				<Label for="email">Email address</Label>
        <div className="field-wrapper">
          <Input name="email" id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} invalid={!!errors['email']} />
          {errors['email'] ? (
            <FormText color="danger">{errors['email']}</FormText>
          ) : null}
        </div>
			</FormGroup>
			<FormGroup className={`${!!errors['phone'] ? 'error' : ''}`}>
				<Label for="phone">Phone number</Label>
        <div className="field-wrapper">
          <Input name="phone" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} invalid={!!errors['phone']} />
          {errors['phone'] ? (
            <FormText color="danger">{errors['phone']}</FormText>
          ) : null}
        </div>
			</FormGroup>
			<FormGroup className={`${!!errors['message'] ? 'error' : ''}`}>
				<Label for="message">Message</Label>
        <div className="field-wrapper">
            <Input type="textarea" name="message" id="message" rows="10" value={message} onChange={(e) => setMessage(e.target.value)} invalid={!!errors['message']} />
            {errors['message'] ? (
              <FormText color="danger">{errors['message']}</FormText>
            ) : null}
        </div>
			</FormGroup>

			<Modal isOpen={confirmDialogOpened} centered={true} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}>Submit message</ModalHeader>
				<ModalBody>
					<form>
						<FormGroup className={`checkbox ${!!errors['acceptTerms'] ? 'error' : ''}`} check>
							<Label check>
								<Input type="checkbox" checked={acceptTerms} invalid={!!errors['acceptTerms']} onClick={() => setAcceptTerms(!acceptTerms)}/>{' '}
								I confirm that I have read and agree to the Terms & Conditions.
							</Label>
						</FormGroup>
						<FormGroup className={`checkbox ${!!errors['privacyPolicy'] ? 'error' : ''}`} check>
							<Label check>
								<Input type="checkbox" invalid={!!errors['privacyPolicy']} checked={privacyPolicy} onClick={() => setPrivacyPolicy(!privacyPolicy)} />{' '}
								I consent to the storage and processing of my personal information by Mockless and third-party tools as outlined in Mockless's Privacy Policy. I confirm that I have read and agree to the Privacy Policy.
							</Label>
						</FormGroup>
						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={process.env.REACT_APP_CAPTCHA}
							onChange={setCaptcha}
							className="mt-3"
						/>
					</form>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-small secondary ml-secondary" onClick={() => setConfirmDialogState(!confirmDialogOpened)}>Cancel</Button>
					<Button className="btn btn-small primary ml-primary" disabled={isConfirmDialogDisabled} onClick={onFinalSubmit}>Submit</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={isLoading} centered={true} className="spinner-modal">
				<Spinner color="light" style={{ width: '3rem', height: '3rem' }} />{' '}
			</Modal>

			<Button className="btn btn-medium primary mt-4 ml-primary" disabled={isFormDisabled} onClick={onSubmit}>Submit</Button>
		</form>
	);
}

export default ContactForm;
