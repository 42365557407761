import * as React from "react";
import Container from "../container/Container";
import ContactForm from "./ContactForm";
import "./style.css";

export default function () {
  return (
    <div className="contact">
      <Container>
        <div className="card-floating card-body">
          <ContactForm />
        </div>
      </Container>
    </div>
  );
}
